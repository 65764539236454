<style>
@media only screen and (max-width: 480px) {
  .swiper-wrapper {
    display: flex;
    flex-direction: column;
  }

  .swiper-slide {
    width: 100% !important;
  }
}
</style>
<template>
  <div>
    <div>
      <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">Bulk Land</h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon icon="HomeIcon" size="14" class="align-text-top" />
                </b-breadcrumb-item>
                <b-breadcrumb-item active> BULK LAND </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <b-card>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>In SIR?</label>
              <v-select
                placeholder="None"
                v-model="sir"
                :options="['Yes', 'No']"
                @input="handleSir($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Select Village (New Survay No.)</label>
              <v-select
                placeholder="None"
                v-model="village"
                label="villagename"
                @input="getVillageData($event)"
                :options="villageOption"
              >
                <template #option="{ villagename, surveynonew }">
                  <span class="font-weight-bolder"> {{ villagename }} </span
                  ><span>({{ surveynonew }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="village">
            <label for=""></label>
            <b-form-group>
              <b-button variant="primary" @click="handleBookLand">Book</b-button>
            </b-form-group>
          </b-col>

          <b-row>
            <b-col>
              <b-button
                v-if="village"
                variant="primary"
                type="submit"
                @click="download"
                class="ml-1 w-226px"
              >
                <feather-icon icon="DownloadIcon" /> Download KMZ File
              </b-button>
            </b-col>
          </b-row>

          <b-row v-if="village && village.description">
            <b-col cols="12">
              <b-card style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
                <h3 class="bookingTitle m-1 d-flex align-items-center">
                  <b-avatar class="mr-1" variant="danger" size="35">
                    <feather-icon size="17" icon="BookOpenIcon" />
                  </b-avatar>
                  Project Description
                </h3>
                <b-card-body>
                  <p>
                    {{ village.description }}
                  </p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-col md="12" v-if="village">
            <h3 class="bookingTitle m-1 d-flex align-items-center">
              <b-avatar class="mr-1" variant="danger" size="35">
                <feather-icon size="17" icon="MapIcon" />
              </b-avatar>
              Land Details
            </h3>

            <b-row>
              <b-col md="3" v-if="sir == 'Yes' && zone">
                <!-- <b-form-group>
                      <label>Zone</label>
                      <b-form-input v-model="zone.name" disabled />
                    </b-form-group> -->

                <bookingInfo
                  :statistic="zone.name"
                  statistic-title="Zone"
                  color="success"
                />
              </b-col>
              <b-col md="3" v-if="sir == 'Yes' && tpno">
                <!-- <b-form-group>
                      <label>T.P.</label>
                      <b-form-input  v-model="tpno" disabled />

                    </b-form-group> -->

                <bookingInfo :statistic="tpno" statistic-title="T.P." color="success" />
              </b-col>
              <b-col md="3" v-if="sir == 'Yes' && subtpno">
                <!-- <b-form-group>
                      <label>Sub T.P.</label>
                      <b-form-input  v-model="subtpno" disabled />
                    </b-form-group> -->
                <bookingInfo
                  :statistic="subtpno"
                  statistic-title="Sub T.P."
                  color="success"
                />
              </b-col>

              <b-col md="3" v-if="newsurveyno">
                <!-- <b-form-group>
                      <b-form-input  v-model="newsurveyno" disabled />
                    </b-form-group> -->
                <bookingInfo
                  :statistic="newsurveyno"
                  statistic-title="New Survay No."
                  color="success"
                />
              </b-col>

              <b-col md="3" v-if="oldsurveyno">
                <!-- <b-form-group>
                      <label>Old Survay No.</label>

                      <b-form-input  v-model="oldsurveyno" disabled />
                    </b-form-group> -->

                <bookingInfo
                  :statistic="oldsurveyno"
                  statistic-title="Old Survay No."
                  color="success"
                />
              </b-col>
              <b-col md="3" v-if="fpno">
                <!-- <b-form-group>
                      <label>F.P. No.</label>
                      <b-form-input  v-model="fpno" disabled />
                    </b-form-group> -->

                <bookingInfo
                  :statistic="fpno"
                  statistic-title="F.P. No."
                  color="success"
                />
              </b-col>
              <b-col md="3" v-if="state">
                <!-- <b-form-group>
                      <label>State</label>
                      <b-form-input  v-model="state" disabled />
                    </b-form-group> -->

                <bookingInfo :statistic="state" statistic-title="State" color="success" />
              </b-col>
              <b-col md="3" v-if="dist">
                <bookingInfo :statistic="dist" statistic-title="City" color="success" />
                <!--
                    <b-form-group>
                      <label>city</label>
                      <b-form-input  v-model="dist" disabled />
                    </b-form-group> -->
              </b-col>

              <b-col md="3">
                <bookingInfo
                  :statistic="fparea"
                  statistic-title="F.P. Area /Land size (Yd)"
                  color="success"
                />

                <!-- <b-form-group>
                      <label>F.P. Area /Land size (In Sq. Yard)</label>
                      <b-form-input v-model="fparea" placeholder="Enter F.P. Area" type="number" disabled />
                    </b-form-group> -->
              </b-col>

              <b-col md="3" v-if="frontrodesize">
                <bookingInfo
                  :statistic="frontrodesize"
                  statistic-title="Front Road Size (MTR)"
                  color="success"
                />

                <!-- <b-form-group>
                      <label>Front Road Size</label>
                      <small>(In Sq.MTR)</small>
                      <b-form-input
                        v-model="frontrodesize"
                        disabled
                      />
                    </b-form-group> -->
              </b-col>

              <b-col md="3" v-if="siderodesize1">
                <b-form-group>
                  <!-- <label>Side Road Size</label>
                      <small>(In Sq.MTR)</small> -->
                  <bookingInfo
                    :statistic="siderodesize1"
                    statistic-title="Side Road Size (MTR)"
                    color="success"
                  />

                  <!-- <b-form-input
                        v-model="siderodesize1"
                        disabled

                      /> -->
                </b-form-group>
              </b-col>

              <b-col md="3" v-if="siderodesize2">
                <b-form-group>
                  <!-- <label>Side Road Size</label>
                      <small>(In Sq.MTR)</small> -->
                  <bookingInfo
                    :statistic="siderodesize2"
                    statistic-title="Side Road Size (MTR)"
                    color="success"
                  />
                  <!--
                      <b-form-input
                        v-model="siderodesize2"
                        disabled

                      /> -->
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="othersideroadsize">
                <bookingInfo
                  :statistic="othersideroadsize"
                  statistic-title="Other Side Road Size"
                  color="success"
                />

                <!-- <b-form-group>
                      <label>Other Side Road Size</label>
                      <small>(In Sq.MTR)</small>

                      <b-form-input
                        v-model="othersideroadsize"
                        disabled
                      />
                    </b-form-group> -->
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12" v-if="village">
            <b-card no-body>
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="success" size="35">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-currency-rupee"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
                    />
                  </svg>
                </b-avatar>
                Rate Details
              </h3>
              <b-card-body>
                <b-row>
                  <b-col md="3" v-if="landrate">
                    <bookingInfo
                      :statistic="landrate"
                      statistic-title="Bulk Land Rate"
                      color="danger"
                    />
                  </b-col>
                  <b-col md="3" v-if="withna == 'No' && nacharges">
                    <bookingInfo
                      :statistic="nacharges"
                      statistic-title="NA Charges Per Bigha"
                      color="danger"
                    />
                  </b-col>

                  <b-col md="3" v-if="jantrirate">
                    <bookingInfo
                      :statistic="jantrirate"
                      statistic-title="Jantri Rate"
                      color="danger"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row md="12" v-if="highlights.length > 0" class="mt-1">
          <b-card
            no-body
            style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important"
          >
            <h3 class="bookingTitle m-1 d-flex align-items-center">
              <b-avatar class="mr-1" variant="success" size="35">
                <feather-icon size="17" icon="SlackIcon" />
              </b-avatar>
              Heighlights
            </h3>
            <b-card-body>
              <b-row class="d-flex justify-content-between flex-wrap">
                <b-col v-for="(item, index) in highlights" :key="index" md="4">
                  <heighlights
                    :statistic="item.name"
                    :statistic-title="index + 1"
                    color="success"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-row>

        <b-row>
          <b-col v-if="village && landlocation" cols="12" class="pt-3 d-flex flex-column">
            <b-card style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="warning" size="35">
                  <feather-icon size="17" icon="MapPinIcon" />
                </b-avatar>
                Land Location
              </h3>
              <b-card-body>
                <iframe
                  :src="landlocation"
                  class="mt-2 w-100"
                  style="border: 5"
                  height="500"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="village" cols="12" class="pt-3 d-flex flex-column">
            <b-card style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="success" size="35">
                  <feather-icon size="17" icon="MapPinIcon" />
                </b-avatar>
                Guide Line
              </h3>
              <b-card-body>
                <img
                  :src="guide"
                  class="mt-2 w-100"
                  style="border: 5"
                  height="500"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="village && landvideo" cols="12" class="pt-3 d-flex flex-column">
            <b-card style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="info" size="35">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-play"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                    />
                  </svg>
                </b-avatar>
                Land Video
              </h3>
              <b-card-body>
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  :src="landvideo.replace('youtu.be', 'www.youtube.com/embed')"
                  allowfullscreen
                />
              </b-card-body>
            </b-card>
          </b-col>

          <b-col v-if="village && landvideo" cols="12" class="pt-3 d-flex flex-column">
            <b-card style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="primary" size="35">
                  <feather-icon size="17" icon="MapPinIcon" />
                </b-avatar>
                Land Map
              </h3>
              <b-card-body>
                <attachment :data="mapimage" />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { Vue } from "vue";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BAvatar,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BButton,
  BBreadcrumb,
  BEmbed,
  BBreadcrumbItem,
  BDropdown,
  BDropdownItem,
  BImgLazy,
  BImg,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import bookingInfo from "@core/components/statistics-cards/bookingInfo.vue";

import { BTable } from "bootstrap-vue";
import axios from "@/components/axios";
import vSelect from "vue-select";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import image from "../../../assets/images/pages/no-image.png";
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../components/Attechment.vue";

import ImageMapView from "../../../components/ImageMapView.vue";
import VueGoodshare from "./share/VueGoodshare.vue";
import VueGoodshareEmail from "./share/providers/Email.vue";
import VueGoodshareFacebook from "./share/providers/Facebook.vue";
import VueGoodshareWhatsApp from "./share/providers/WhatsApp.vue";
import VueGoodshareSms from "./share/providers/SMS.vue";
import heighlights from "@/@core/components/statistics-cards/heighlights.vue";

export default {
  components: {
    heighlights,
    ImageMapView,
    VueGoodshare,
    VueGoodshareEmail,
    VueGoodshareFacebook,
    VueGoodshareWhatsApp,
    VueGoodshareSms,
    // Swiper,
    // SwiperSlide,
    BAvatar,
    BEmbed,
    Attachment,
    BCardCode,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BDropdownItem,
    BBadge,
    PinchScrollZoom,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BFormInput,
    BFormGroup,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    vSelect,
    BImgLazy,
    ValidationProvider,
    BImg,
    bookingInfo,
  },
  async mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.init();
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      plotObj: {},
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      villageOption: [],
      allVillageOption: [],
      highlights: [],
      village: "",
      sir: "",
      zone: "",
      tpno: "",
      subtpno: "",
      newsurveyno: "",
      oldsurveyno: "",
      fpno: "",
      fparea: "",

      cprate: "",
      landrate: "",
      withna: "",
      nacharges: "",
      jantrirate: "",
      landlocation: "",
      selectedProject: "",
      state: "",
      city: "",
      landvideo: "",
      mapimage: "",
      guide: "",
      frontrodesize: "",
      siderodesize1: "",
      siderodesize2: "",
      othersideroadsize: "",
    };
  },
  methods: {
    handleBookLand() {
      let data = {
        village: this.village,
        sir: this.sir,
      };
      localStorage.setItem("landData", JSON.stringify(data));
      this.$router.push("/crm/bulklandsales/addbulklandsales");
    },
    async init() {
      this.getVillages();
    },
    handleSir(e) {
      let villagesSir = [];
      let villages = [];

      this.allVillageOption.map((item) => {
        if (item.tpno) {
          villagesSir.push(item);
        } else {
          villages.push(item);
        }
      });
      if (e == "Yes") {
        this.villageOption = villagesSir;
      } else if (e == "No") {
        this.villageOption = villages;
      }
    },
    download() {
      // const url = this.selectedProject.projectboucher.replace('http','https')
      if (!this.village.kmzfile) {
        this.$swal({
          title: "Warning",
          text: "There Is No File",
          icon: "warning",
          timer: 5000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        let url = this.village.kmzfile.includes("https")
          ? this.village.kmzfile
          : this.village.kmzfile.replace("http", "https");
        const fileURL = url;
        const fURL = document.createElement("a");
        fURL.href = fileURL;
        fURL.setAttribute("download", `KMZ File`);

        document.body.appendChild(fURL);
        fURL.click();
      }
    },
    // window.open(this.selectedProject.projectboucher)

    getVillageData(e) {
      if (e) {
        this.newsurveyno = e.surveynonew;
        this.oldsurveyno = e.surveynoold;
        this.tpno = e.tpno;
        this.subtpno = e.subtpno;
        this.fpno = e.fpno;
        this.fparea = e.isfp == "Yes" ? e.fparea : e.totalarea;
        this.cprate = e.cprate;
        this.withna = e.withna;
        this.nacharges = e.nacharges;
        this.landrate = e.marketrate;
        this.totalpayment = this.landrate * this.fparea;
        this.zone = e.zone ? e.zone : "";
        this.jantrirate = e.Jantrirate;
        this.state = e.state;
        this.dist = e.dist;
        this.mapimage = e.mapimage;
        this.frontrodesize = e.frontrodesize;
        this.siderodesize1 = e.siderodesize1;
        this.siderodesize2 = e.siderodesize2;
        this.othersideroadsize = e.othersideroadsize;
        this.guide = e.guidline;
      }

      if (e.landloacation && e.landloacation.includes("src=")) {
        this.landlocation = e.landloacation.split('src="')[1].split('"')[0];
      }
      if (e.landvideo && e.landvideo.includes("src=")) {
        this.landvideo = e.landvideo.split('src="')[1].split('"')[0];
      }
      this.highlights = e.highlights ? JSON.parse(e.highlights) : [];
      this.highlights = this.highlights.filter((item) => (item.name ? true : false));
    },
    async getVillages() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/landmaster`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allVillageOption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },

    handleBook() {
      localStorage.removeItem("plotData");
      const plotData = {
        project: this.selectedProject,
        projectid: this.selectedProject.id,
        plot: this.selectedPlot.name,
        plotid: this.selectedPlot.id,
        dashboard: true,
      };
      localStorage.setItem("plotData", JSON.stringify(plotData));
      this.$router.push("/crm/sales/addsales");
    },
  },
};
</script>

<style></style>
